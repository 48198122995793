import { Component } from '@angular/core';

@Component({
  selector: 'app-waiting-complete',
  standalone: true,
  imports: [],
  templateUrl: './waiting-complete.component.html',
  styleUrl: './waiting-complete.component.scss'
})
export class WaitingCompleteComponent {

}
