import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import {
  DynamicFieldCreate,
  DynamicFieldDetails,
  GetFieldParams
} from '@core/models/interfaces/data-config/dynamic-field';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicFieldService {
  private baseUrl = `${environment.API_URL}/crm/dynamic-fields`;

  constructor(private httpClient: HttpClient) {}

  // GET FIELD LIST
  getDynamicFields(params: GetFieldParams) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('keyword', params.keyword);
    queryParams = queryParams.append('eModule', params.eModule);
    if (params.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }
    if (params.sort !== undefined) {
      queryParams = queryParams.append('sorts', params.sort);
    }

    return this.httpClient.get<ResponseListApi<DynamicFieldDetails>>(this.baseUrl, {
      params: queryParams
    });
  }

  // GET FIELD BY ID
  getDynamicFieldById(id: string, parentFieldId?: string) {
    let queryParams = new HttpParams();
    if (parentFieldId) {
      queryParams = queryParams.append('parentFieldId', parentFieldId);
    }

    return this.httpClient.get<ResponseApi<DynamicFieldDetails>>(`${this.baseUrl}/${id}`, {
      params: queryParams
    });
  }

  // CREATE DYNAMIC FIELD
  createDynamicField(body: DynamicFieldCreate) {
    return this.httpClient.post<ResponseApi<DynamicFieldDetails>>(this.baseUrl, body);
  }

  // UPDATE DYNAMIC FIELD BY ID
  updateDynamicFieldById(id: string, body: DynamicFieldCreate, parentFieldId?: string) {
    let queryParams = new HttpParams();
    if (parentFieldId) {
      queryParams = queryParams.append('parentFieldId', parentFieldId);
    }
    return this.httpClient.put<ResponseApi<DynamicFieldDetails>>(`${this.baseUrl}/${id}`, body, {
      params: queryParams
    });
  }

  // DELETE DYNAMIC FIELD BY ID
  deleteDynamicFieldById(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }

  getCustomFields(eModule: string) {
    return this.httpClient.get<ResponseApi<DynamicFieldDetails[]>>(`${this.baseUrl}/custom-fields?eModule=${eModule}`);
  }
}
