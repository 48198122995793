import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseListApi } from '@core/models/interfaces/common';
import {
  GetPerformanceParams,
  GetProductDetailsReportParams,
  LeadReport,
  PerformanceReport,
  ProductDetailsReport,
  ProductItemReportData
} from '@core/models/interfaces/report';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  readonly baseUrl = `${environment.API_URL}/crm/report`;

  constructor(private httpClient: HttpClient) {}

  private getProductDetailsReportParams(params: GetProductDetailsReportParams) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('groupBy', params.groupBy);

    if (params?.commissionTeamIds !== undefined) {
      for (const id of params.commissionTeamIds) {
        if (id) {
          queryParams = queryParams.append('commissionTeamIds', id);
        }
      }
    }

    if (params?.leadSourceIds !== undefined) {
      for (const id of params.leadSourceIds) {
        if (id) {
          queryParams = queryParams.append('leadSourceIds', id);
        }
      }
    }

    if (params?.statusIds !== undefined) {
      for (const id of params.statusIds) {
        if (id) {
          queryParams = queryParams.append('statusIds', id);
        }
      }
    }

    if (params?.companyIds !== undefined) {
      for (const id of params.companyIds) {
        if (id) {
          queryParams = queryParams.append('companyIds', id);
        }
      }
    }

    if (params?.productTypeIds !== undefined) {
      for (const id of params.productTypeIds) {
        if (id) {
          queryParams = queryParams.append('productTypeIds', id);
        }
      }
    }

    if (params?.productIds !== undefined) {
      for (const id of params.productIds) {
        if (id) {
          queryParams = queryParams.append('productIds', id);
        }
      }
    }

    if (params?.commissionOwnerIds !== undefined) {
      for (const id of params.commissionOwnerIds) {
        if (id) {
          queryParams = queryParams.append('commissionOwnerIds', id);
        }
      }
    }

    if (params?.commissionAgentIds !== undefined) {
      for (const id of params.commissionAgentIds) {
        if (id) {
          queryParams = queryParams.append('commissionAgentIds', id);
        }
      }
    }

    if (params?.effectiveFrom !== undefined) {
      queryParams = queryParams.append('effectiveFrom', params.effectiveFrom);
    }

    if (params?.effectiveTo !== undefined) {
      queryParams = queryParams.append('effectiveTo', params.effectiveTo);
    }

    if (params?.createdFrom !== undefined) {
      queryParams = queryParams.append('createdFrom', params.createdFrom);
    }

    if (params?.createdTo !== undefined) {
      queryParams = queryParams.append('createdTo', params.createdTo);
    }

    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params?.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params?.sizeElement !== undefined) {
      queryParams = queryParams.append('sizeElement', params.sizeElement);
    }

    return queryParams;
  }

  getProductDetailsReport(params: GetProductDetailsReportParams) {
    let queryParams = this.getProductDetailsReportParams(params);

    return this.httpClient.get<ResponseListApi<ProductDetailsReport>>(`${this.baseUrl}/product-details`, {
      params: queryParams
    });
  }

  getProductDetailsReportById(id: string, params: GetProductDetailsReportParams) {
    let queryParams = this.getProductDetailsReportParams(params);

    return this.httpClient.get<ResponseListApi<ProductItemReportData>>(`${this.baseUrl}/product-details/${id}`, {
      params: queryParams
    });
  }

  exportProductDetailsReport(type: 'PDF' | 'CSV', params: GetProductDetailsReportParams) {
    let queryParams = this.getProductDetailsReportParams(params);

    return this.httpClient.get(`${this.baseUrl}/product-details/export/${type}`, {
      params: queryParams,
      responseType: 'blob'
    });
  }

  private getPerformanceReportParams(params: GetPerformanceParams) {
    let queryParams = new HttpParams();

    if (params?.userIds !== undefined) {
      for (const id of params.userIds) {
        if (id) {
          queryParams = queryParams.append('userIds', id);
        }
      }
    }

    if (params?.groupIds !== undefined) {
      for (const id of params.groupIds) {
        if (id) {
          queryParams = queryParams.append('groupIds', id);
        }
      }
    }

    if (params?.createdFrom !== undefined) {
      queryParams = queryParams.append('createdFrom', params.createdFrom);
    }

    if (params?.createdTo !== undefined) {
      queryParams = queryParams.append('createdTo', params.createdTo);
    }

    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params?.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params?.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    return queryParams;
  }

  getPerformanceReport(params: GetPerformanceParams) {
    let queryParams = this.getPerformanceReportParams(params);

    return this.httpClient.get<ResponseListApi<PerformanceReport>>(`${this.baseUrl}/performance`, {
      params: queryParams
    });
  }

  exportPerformanceReport(type: 'PDF' | 'CSV', params: GetPerformanceParams) {
    let queryParams = this.getPerformanceReportParams(params);

    return this.httpClient.get(`${this.baseUrl}/performance/export/${type}`, {
      params: queryParams,
      responseType: 'blob'
    });
  }

  getLeadReport(params: GetPerformanceParams) {
    let queryParams = this.getPerformanceReportParams(params);

    return this.httpClient.get<ResponseListApi<LeadReport>>(`${this.baseUrl}/lead`, {
      params: queryParams
    });
  }

  exportLeadReport(type: 'PDF' | 'CSV', params: GetPerformanceParams) {
    let queryParams = this.getPerformanceReportParams(params);

    return this.httpClient.get(`${this.baseUrl}/lead/export/${type}`, {
      params: queryParams,
      responseType: 'blob'
    });
  }

  logEventReport(type: string) {
    return this.httpClient.post(`${this.baseUrl}/log-event/${type}`, {});
  }
}
