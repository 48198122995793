export enum FieldTypeEnum {
  SELECT = 'SELECT',
  CURRENCY = 'CURRENCY',
  DATETIME = 'DATETIME',
  MULTI_SELECT = 'MULTI_SELECT',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  DEPENDENT_DROPDOWN = 'DEPENDENT_DROPDOWN'
}

export enum FieldModuleEnum {
  LEAD = 'LEAD',
  NOTE = 'NOTE',
  POLICY = 'POLICY',
  TASK = 'TASK',
  USER = 'USER'
}

export enum DataConfigurationSortFieldEnum {
  NAME = 'name',
  TYPE = 'fieldType',
  OPTION = 'optionSize',
  REQUIRED = 'required',
  CREATEDBY = 'createdBy.fullName',
}