import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DndDirective } from '@shared/directives/dnd.directive';

@Component({
  selector: 'app-upload-file',
  standalone: true,
  imports: [DndDirective, CommonModule, TranslateModule],
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.scss'
})
export class UploadFileComponent {
  @Input() multiple = false;
  @Input() compact = false;
  @Input() styleClass = '';
  @Input() accepts = '';
  @Input() description = 'SVG, PNG, JPG or GIF (max 2MB)';
  @Output() onUploadFile = new EventEmitter<FileList>();

  onFileDropped(event: FileList) {
    this.onUploadFile.emit(event);
  }

  onChangeFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (files) {
      this.onUploadFile.emit(files);
    }
  }
}
