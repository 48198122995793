export enum TYPE_FILE {
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  MP4 = 'video/mp4'
}
export enum LeadAttachmentSortFieldEnum {
  NAME = 'originalName',
  TYPE = 'type.name',
  POLICY = 'policy.number',
  UPDATE_BY = 'addBy.fullName',
  CREATED_AT = 'createdAt'
}
