import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true
})
export class FileSizePipe implements PipeTransform {
  transform(value: number, unit: string = 'auto'): string {
    if (value === 0) return `0 ${unit}`;

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let i: number;
    if (unit === 'auto') {
      i = Math.floor(Math.log(value) / Math.log(k));
    } else {
      i = sizes.indexOf(unit);
      if (i === -1) throw new Error(`Invalid unit: ${unit}`);
    }

    const convertedValue = parseFloat((value / Math.pow(k, i)).toFixed(2));
    return `${convertedValue} ${sizes[i]}`;
  }
}
