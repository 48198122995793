<div
  appDnd
  (fileDropped)="onFileDropped($event)"
  class="flex flex-col gap-3 items-center border border-dashed p-4 rounded-sm hover:bg-neutral-100 {{ styleClass }}"
  [ngClass]="[compact ? 'w-[120px] h-[120px] flex items-center justify-center' : 'min-w-[300px]']">
  <div class="h-9 w-9 rounded-full bg-neutral-200 flex items-center justify-center">
    <i class="icon-wl-upload-cloud text-6"></i>
  </div>
  @if (compact) {
    <span class="text-blue-600 font-medium cursor-pointer hover:underline" (click)="uploadFile.click()">{{
      'action.upload' | translate
    }}</span>
  } @else {
    <div class="flex flex-col gap-1">
      <div class="text-center">
        <span class="text-blue-600 font-medium cursor-pointer hover:underline" (click)="uploadFile.click()">{{
          'common.click-to-upload' | translate
        }}</span>
        {{ 'common.or' | translate }} {{ 'common.drag-and-drop' | translate }}
      </div>
      <div class="text-neutral-600">{{ description }}</div>
    </div>
  }
</div>

<input
  class="hidden"
  #uploadFile
  type="file"
  [multiple]="multiple"
  [accept]="accepts"
  (change)="onChangeFile($event)"
  (click)="uploadFile.value = ''" />
